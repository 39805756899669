<template>
    <v-app>
        <DashboardCoreView />
        <Drawer />
    </v-app>
</template>
<script>

export default {
    name: 'inicio',

    components: {
        Drawer: () => import('./drawer.vue'),
        DashboardCoreView: () => import('./View.vue')
    }
}
</script>
